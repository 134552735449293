import React, { useEffect, useState } from "react"
import { Flex, Text, Box } from "theme-ui"

import Layout from "../../layouts"
import AccountLayout from "../../layouts/account-layout"
import Medusa from "../../../services/api"
import { useAccountContext } from "../../../context/AccountContext"

import useWindowSize from "../../../hooks/useWindowSize"
import OrderOverview from "../order-components/oder-overview"
import ReturnPage from "../order-components/in-account-return"
import OrderDetails from "../order-components/order-details"
import { useNotificationContext } from "../../../context/NotificationContext"

const MyOrders = () => {
  const [orders, setOrders] = useState([])
  const { id, logOut } = useAccountContext()
  const { pushNotification } = useNotificationContext()
  const [detailedOrder, setDetailedOrder] = useState({
    isVisible: false,
    order: {},
  })
  const [returnOrder, setReturnOrder] = useState({
    isVisible: false,
    order: {},
  })

  const { width } = useWindowSize()

  useEffect(() => {
    const fetchOrders = () => {
      Medusa.customers
        .listOrders(id)
        .then(({ data }) => {
          if (Array.isArray(data?.orders)) {
            setOrders(data.orders)
          }
        })
        .catch(() => {
          pushNotification({
            id: "session_expired",
            body: "Your session has expired. Please login again",
            dismiss: {
              duration: 3000,
            },
          })
          logOut()
        })
    }

    fetchOrders()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  const handleContentRendered = () => {
    if (returnOrder.isVisible) {
      return (
        <ReturnPage
          order={returnOrder.order}
          onClose={() => setReturnOrder({ isVisible: false, order: {} })}
        />
      )
    }
    if (detailedOrder.isVisible) {
      return (
        <OrderDetails
          order={detailedOrder.order}
          onClose={() => setDetailedOrder({ isVisible: false, order: {} })}
        />
      )
    }

    return (
      <Box sx={{ width: "100%" }}>
        <Text
          as="h1"
          sx={{
            fontWeight: "normal",
            fontSize: "21px",
            px: ["16px", "16px", 0],
            mb: "22px",
          }}
        >
          My orders
        </Text>
        <Flex sx={{ flexWrap: "wrap" }}>
          {orders.length > 0 &&
            orders.map((order) => (
              <OrderOverview
                key={order.id}
                order={order}
                handleViewOrder={() =>
                  setDetailedOrder({ isVisible: true, order: order })
                }
                onReturnClicked={() => {
                  setReturnOrder({ isVisible: true, order: order })
                }}
              />
            ))}
        </Flex>
      </Box>
    )
  }

  return (
    <Layout navbarTransparent={width > 992}>
      <AccountLayout noPaddingMobile={width < 992}>
        {handleContentRendered()}
      </AccountLayout>
    </Layout>
  )
}

export default MyOrders
