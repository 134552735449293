import React, { useState } from "react"
import styled from "@emotion/styled"
import { Flex, Text, Box } from "theme-ui"

import Arrow from "../../../assets/svg/arrow-left.svg"
import OrderDetailsInformation from "./order-details-information"
import Image from "../../new-ui/image"
import { formatMoneyAmount } from "../../../utils/prices"
import ReturnPage from "./in-account-return"

const OrderDetails = ({ order, onClose }) => {
  const [returnOpen, setReturnOpen] = useState(false)

  if (returnOpen) {
    return <ReturnPage order={order} onClose={() => setReturnOpen(false)} />
  }

  return (
    <Flex
      sx={{
        flexDirection: "column",
        minHeight: "100vh",
        color: "primary",
      }}
      className="order-details"
    >
      <Flex
        onClick={onClose}
        sx={{
          padding: ["4%", 0],
          pb: "16px",
          fontSize: "14px",
          fontWeight: "bold",
          alignItems: "center",
          cursor: "pointer",
          ".go-back-arrow": { marginRight: "8px" },
        }}
      >
        <Arrow className="go-back-arrow" />
        <Text>go back</Text>
      </Flex>
      <Flex
        sx={{
          alignItems: ["flex-start", "flex-end"],
          flexDirection: ["column", "row"],
          padding: ["4%", 0],
          py: [0, "8px"],
        }}
      >
        <Text
          as="h1"
          sx={{
            fontSize: "21px",
            fontWeight: "normal",
            flex: 1,
          }}
        >
          Order number: {order.display_id}
        </Text>
        {["shipped"].some((status) => status === order.fulfillment_status) && (
          <Text
            sx={{
              textDecoration: "underline",
              fontSize: "15px",
              cursor: "pointer",
              mt: ["8px", 0],
            }}
            onClick={() => setReturnOpen(true)}
          >
            Create return
          </Text>
        )}
      </Flex>
      <OrderDetailsInformation
        order={order}
        expanded={true}
        sx={{
          backgroundColor: "grayscale.white",
          p: "1em",
          my: "1em",
          flexDirection: ["column", "row"],
          flexWrap: "wrap",
          ">div": {
            flex: "0 1 30%",
          },
          border: "grey",
        }}
      />
      <Flex
        sx={{
          flexDirection: "column",
          border: "grey",
          ">div:first-of-type": {
            paddingTop: ["16px", "24px"],
          },
          ">div:not(:first-of-type)": {
            paddingTop: 0,
          },
          ">div:last-of-type": {
            border: "none",
          },
        }}
      >
        {order.items.map((item, index, array) => (
          <Item
            key={item.id}
            item={item}
            order={order}
            isLast={index === array.length - 1}
          />
        ))}
      </Flex>
    </Flex>
  )
}

const Hairline = styled(Flex)`
  height: 1px;
`

const Item = ({ item, order, isLast }) => {
  return (
    <Flex
      sx={{
        flexDirection: "column",
        backgroundColor: "grayscale.white",
        color: "primary",
        padding: ["16px", "24px"],
        paddingTop: 0,
      }}
    >
      <Flex
        sx={{
          ".image": { flex: 1, objectFit: "cover" },
          ".information": { flex: [2, 4] },
        }}
      >
        <Image src={item.thumbnail} className="image" />
        <Flex
          className="information"
          sx={{
            px: ["1em", "2em"],
            flexDirection: "column",

            ".name-and-price": {
              flex: 1,
            },
            ".details": {
              fontSize: "14px",
            },
          }}
        >
          <div className="name-and-price">
            <Text
              as="h2"
              sx={{ fontSize: "19px", fontWeight: "normal", mb: "8px" }}
            >
              {item.title}
            </Text>
            <Text as="p" sx={{ fontSize: "14px" }}>
              {formatMoneyAmount(
                {
                  amount: item.unit_price,
                  currencyCode: order.currency_code,
                },
                2,
                order.tax_rate
              )}
            </Text>
          </div>
          <div className="details">
            {item.variant?.product?.subtitle && (
              <Box>
                <Text sx={{ mr: "8px", fontWeight: "bold" }}>Color</Text>
                <Text>{item.variant?.product?.subtitle}</Text>
              </Box>
            )}
            {item.variant?.title && (
              <Box>
                <Text sx={{ mr: "8px", fontWeight: "bold" }}>Size</Text>
                <Text>{item.variant?.title}</Text>
              </Box>
            )}
          </div>
        </Flex>
      </Flex>
      {!isLast && (
        <Hairline sx={{ borderBottom: "grey", paddingTop: ["16px", "24px"] }} />
      )}
    </Flex>
  )
}

export default OrderDetails
