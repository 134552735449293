import React, { useState } from "react"
import styled from "@emotion/styled"

import { Text } from "theme-ui"

import Button from "../new-ui/button"
import Dismiss from "../../assets/svg/dismiss.svg"

import { navigate } from "gatsby"
import getDisplayItems from "../../utils/get-display-items"

const ButtonWrapper = styled.div`
  padding-top: 11px;
  margin: 0 auto;
  text-align: center;
`

export const Modal = styled.div`
  display: ${(props) => (props.show ? "none" : "none")};
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10000;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const ModalContent = styled.div`
  overflow-y: auto;
  background-color: white;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 40px;
  padding-top: 20px;
  max-width: 100%;
  max-height: 90vh;
  margin: 0 10px;

  ${(props) => props.theme.breakpointsLegacy.tablet} {
    max-width: 460px;
    margin: 0;
  }
`

const OutOfStockItemContainer = styled.div``

const OutOfStockItemDetails = styled.div`
  display: flex;
  padding-top: 13px;
  padding-bottom: 13px;

  &:not(:first-child) {
    border-top: 1px solid black;
  }
`

const OutOfStockThumbnail = styled.img`
  width: 150px !important;
`

const OutOfStockItemDetailsInfo = styled.div`
  padding-left: 15px;
  font-size: 13px;
`

const DismissContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 17px;

  svg {
    cursor: pointer;
  }
`

const OutOfStockItem = ({ item }) => {
  return (
    <OutOfStockItemDetails>
      <OutOfStockThumbnail src={item.thumbnail} alt={item.title} />
      <OutOfStockItemDetailsInfo>
        <div>{item.title}</div>
        <div>Size: {!item.is_giftcard && item.description}</div>
        {item.variant?.metadata?.color && (
          <div>Color: {item.variant.metadata.color}</div>
        )}
      </OutOfStockItemDetailsInfo>
    </OutOfStockItemDetails>
  )
}

const OutOfStockPopup = ({ lineItems, hidePopup, cancelOrder, order }) => {
  const [loading, setLoading] = useState(false)
  let items

  order
    ? (items = getDisplayItems({ items: order.items }))
    : (items = getDisplayItems({ items: lineItems }))

  return (
    <Modal>
      <ModalContent>
        <DismissContainer>
          <div>
            <Text
              as="h2"
              sx={{
                fontSize: "19px",
                fontWeight: "normal",
              }}
            >
              {cancelOrder
                ? "Request to cancel your order"
                : "Some of your items have sold out"}
            </Text>
            <Text
              as="h4"
              sx={{
                fontSize: "13px",
                fontWeight: "normal",
                paddingTop: "5px",
              }}
            >
              {cancelOrder
                ? "If your order has not been shipped, it will be cancelled and the amount will be refunded to your account"
                : "We have removed these items from your cart"}
            </Text>
            {cancelOrder && (
              <Text
                as="h4"
                sx={{
                  fontSize: "13px",
                  fontWeight: "normal",
                  paddingTop: "5px",
                }}
              >
                In case of any technical difficulties, please reach out to the
                Customer Service.
              </Text>
            )}
          </div>
          <Dismiss onClick={() => hidePopup()} />
        </DismissContainer>
        <OutOfStockItemContainer>
          {items &&
            items.map((item, i) => <OutOfStockItem key={i} item={item} />)}
        </OutOfStockItemContainer>
        <ButtonWrapper>
          {!cancelOrder && (
            <>
              <Button
                variant="light"
                sx={{ marginBottom: "8px", maxWidth: "310px", width: "100%" }}
                onClick={() => {
                  hidePopup()
                  navigate(`/l/all-products`)
                }}
              >
                Shop other items
              </Button>
              <Button
                sx={{ maxWidth: "310px", width: "100%" }}
                onClick={() => hidePopup()}
              >
                Continue checkout
              </Button>
            </>
          )}
          {cancelOrder && loading ? (
            <Button sx={{ maxWidth: "310px", width: "100%" }}>
              Canceling order
            </Button>
          ) : (
            <Button
              sx={{ maxWidth: "310px", width: "100%" }}
              onClick={() => {
                setLoading(true)
                cancelOrder(order)
              }}
            >
              Request cancellation
            </Button>
          )}
        </ButtonWrapper>
      </ModalContent>
    </Modal>
  )
}

export default OutOfStockPopup
