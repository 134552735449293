import React, { useState } from "react"
import { Flex, Text } from "@theme-ui/components"
import styled from "@emotion/styled"
import useWindowSize from "../../../hooks/useWindowSize"
import { renderMobileOrDesktop } from "../../../utils/render-util"
import Button from "../../new-ui/button"
import Arrow from "../../../assets/svg/arrow-left.svg"
import Image, { ImageCarousel } from "../../new-ui/image"
import OrderDetailsInformation from "./order-details-information"
import Medusa from "../../../services/api"
import { useNotificationContext } from "../../../context/NotificationContext"
import OrderCancellationModal from "../../ui/OutOfStock.js"
import { useInterfaceContext } from "../../../context/InterfaceContext"

const OrderContainer = styled(Flex)`
  flex-direction: column;
  background-color: ${(props) => props.theme.colors.grayscale.white};
  border: ${(props) => props.theme.borders.grey};
  padding: 24px;
  margin: 1em 0;

  flex: 1 1 100%;
  font-size: 14px;

  ${(props) => props.theme.breakpointsLegacy.desktop} {
    height: 390px;
    flex-direction: row;
  }
`

const ImageContainer = styled(Flex)`
  overflow: hidden;
  flex-wrap: no-wrap;

  img {
    object-fit: contain;
  }

  > div {
    flex: 0 0 25%;

    :not(:first-of-type) {
      padding-left: 3px;
    }
    :not(:last-of-type) {
      padding-right: 3px;
    }
  }

  ${(props) => props.theme.breakpointsLegacy.desktop} {
    display: flex;
    flex-wrap: wrap;

    > div {
      flex: 0 0 48%;
      margin: 2px;

      height: 50%;

      :first-of-type {
        &.last {
          flex-basis: 100%;
          height: 100%;

          img {
            object-fit: cover;
            height: auto;
          }
        }
      }
      :not(:first-of-type) {
        padding-left: 0px;
      }
      :not(:last-of-type) {
        padding-right: 0px;
      }
    }
  }
`

const ExternalLink = styled.a`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 8px;
  color: ${(props) => props.theme.colors.primary};
  margin-bottom: 2px;

  .track-order-arrow {
    margin-left: 12px;
    transform: rotate(180deg);
  }
`

const OrderOverview = ({ order, handleViewOrder, onReturnClicked }) => {
  const size = useWindowSize()
  const { pushNotification } = useNotificationContext()
  const { setModalContent, setModalVisible } = useInterfaceContext()
  const [orderCanceled, setOrderCanceled] = useState(false)

  const dateDifference = (dateInStringFormat) => {
    const orderDate = new Date(dateInStringFormat)

    const currentDate = new Date()

    return (currentDate - orderDate) / (1000 * 3600 * 24)
  }

  const openModal = () => {
    setModalContent(
      <OrderCancellationModal
        order={order}
        hidePopup={() => setModalVisible(false)}
        cancelOrder={(order) => cancelOrder(order)}
      />
    )

    setModalVisible(true)
  }

  const cancelOrder = async (order) => {
    if (order.fulfillment_status === "fulfilled") {
      await Medusa.orders.cancelFulfillment(order.id, order.fulfillments[0].id)
    }

    Medusa.orders
      .cancel(order.id)
      .then(
        ({ data }) =>
          data &&
          (setModalVisible(false),
          pushNotification({
            id: "order_canceled",
            body: `Order #${order.display_id} has been canceled`,
            dismiss: {
              duration: 4000,
            },
          }),
          setOrderCanceled(true))
      )
      .catch(
        (err) =>
          err &&
          (setModalVisible(false),
          pushNotification({
            id: "order_cancel_error",
            body: `An error occured while trying to cancel order #${order.display_id}`,
            dismiss: {
              duration: 4000,
            },
          }))
      )
  }

  const DesktopItems = (
    <ImageContainer sx={{ flex: 2, maxWidth: "280px" }}>
      {order.items &&
        order.items
          .slice(0, 4)
          .map(
            (item, index, array) =>
              item.thumbnail && (
                <Image
                  key={`${item.thumbnail}-${index}`}
                  src={item.thumbnail}
                  className={
                    index % 2 === 0 && index === array.length - 1
                      ? "last"
                      : "not-last"
                  }
                />
              )
          )}
    </ImageContainer>
  )
  const MobileItems = (
    <ImageCarousel
      sx={{ height: "260px", width: "80%", maxWidth: "260px" }}
      images={order.items.map((item) => {
        return {
          src: item.thumbnail,
        }
      })}
    />
  )

  return (
    <OrderContainer>
      <Flex
        sx={{
          flexDirection: "column",
          flex: 2,
          paddingY: "3px",
          marginBottom: ["36px", "36px", "0"],
        }}
      >
        <Flex sx={{ flexDirection: "column", flex: 1, minHeight: "175px" }}>
          <Flex
            sx={{
              flexDirection: "column",
              fontWeight: "bold",
              marginBottom: "24px",
            }}
          >
            <Text>Order number:</Text>
            <Text>{order.display_id}</Text>
          </Flex>
          <OrderDetailsInformation
            order={order}
            orderCanceled={orderCanceled}
          />
        </Flex>
        <Flex sx={{ flexDirection: "column" }}>
          {order.fulfillments &&
            order.fulfillments
              .reduce((acc, current) => {
                acc.push(...current.tracking_links)

                return acc
              }, [])
              .map((trackingLink) => {
                return (
                  <ExternalLink
                    key={trackingLink.id}
                    mb="8px"
                    href={trackingLink.url}
                  >
                    <Text sx={{ fontWeight: "bold" }}>
                      Track your order - {trackingLink.tracking_number}
                    </Text>
                    <Arrow className="track-order-arrow" />
                  </ExternalLink>
                )
              })}
          <div>
            <Button
              variant="light"
              sx={{ marginTop: "6px", marginRight: "6px" }}
              onClick={handleViewOrder}
            >
              View order
            </Button>
            {["shipped"].some(
              (status) => status === order?.fulfillment_status
            ) &&
              dateDifference(order.fulfillments[0].shipped_at) < 30 && (
                <Button
                  variant="light"
                  sx={{ marginTop: "6px", marginRight: "6px" }}
                  onClick={onReturnClicked}
                >
                  Create return
                </Button>
              )}
            <Button
              variant="light"
              onClick={() => openModal()}
              disabled={
                order?.fulfillment_status === "canceled" ||
                orderCanceled ||
                order?.fulfillment_status === "shipped" ||
                order?.fulfillment_status === "returned" ||
                order?.fulfillment_status === "partially_returned"
              }
              sx={{ marginTop: "6px", marginRight: "10px" }}
            >
              {order?.fulfillment_status === "canceled" || orderCanceled
                ? "Cancellation confirmed"
                : "Cancel order"}
            </Button>
          </div>
          {(order?.fulfillment_status === "shipped" ||
            order?.fulfillment_status === "returned" ||
            order?.fulfillment_status === "partially_returned") && (
            <Text sx={{ marginTop: "8px", marginRight: "10px" }}>
              Your order has already been shipped, and therefore cannot be
              cancelled
            </Text>
          )}
        </Flex>
      </Flex>
      {renderMobileOrDesktop({
        mobile: MobileItems,
        desktop: DesktopItems,
        size: size,
        mobileThreshold: 992,
      })}
    </OrderContainer>
  )
}

export default OrderOverview
