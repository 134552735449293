import React from "react"
import { Flex, Text } from "@theme-ui/components"
import styled from "@emotion/styled"
import { formatCartTotal, formatOrderDiscount } from "../../../utils/prices"

const OrderDetailsContainer = styled(Flex)`
  > span:first-of-type {
    font-weight: bold;
    margin-right: 8px;
  }
`

const OrderDetailsInformation = ({ order, orderCanceled, sx, expanded = false }) => {
  const displayDate = (dateInStringFormat) => {
    const date = new Date(dateInStringFormat)

    return `${date.toLocaleString('en-GB',{month:'long', year:'numeric', day:'numeric'})}`
  }

  let fulfillmentStatus = ""

  switch (order.fulfillment_status) {
    case "not_fulfilled":
    case "fulfilled":
    case "partially_fulfilled":
      fulfillmentStatus = "Placed"
      break;
    case "returned":
    case "partially_returned":
      fulfillmentStatus = "Returned"
      break;
    case "shipped":
    case "partially_shipped":
      fulfillmentStatus = "Shipped"
      break;
    case "canceled":
      fulfillmentStatus = "Cancelled"
      break;
  }

  return (
    <Flex sx={{ flexDirection: "column", ...sx }}>
      <OrderDetailsContainer>
        <Text>Status:</Text>
        <Text>{orderCanceled ? "Cancelled" : (fulfillmentStatus)}</Text>
      </OrderDetailsContainer>
      <OrderDetailsContainer>
        <Text>Order date:</Text>
        <Text>{displayDate(order.created_at)}</Text>
      </OrderDetailsContainer>
      <OrderDetailsContainer>
        <Text>Total:</Text>
        <Text>{formatCartTotal(order)}</Text>
      </OrderDetailsContainer>
      {expanded && (
        <OrderDetailsContainer>
          {order.discount_total > 0 && (
            <>
              <Text>Discount:</Text>
              <Text>{formatOrderDiscount(order)}</Text>
            </>
          )}
        </OrderDetailsContainer>
      )}
    </Flex>
  )
}

export default OrderDetailsInformation
